module.exports = {
  background: '#181919',
  backgroundDark: '#202121',

  text: '#eeeeee',

  primary: '#eeeeee',
  primaryLight: '#9a9a9f',
  primaryDark: '#181919',

  secondary: '#00ffff',
  secondaryLight: '#181919',
  secondaryDark: "#eeeeee"//'#ff0000',
};
